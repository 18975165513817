import React from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from '../components/layout';
import styled from 'styled-components';
import { PublishDate } from '../components/components';
import { palette } from '../theme/palette';

export const query = graphql`
    query {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { unlisted: { ne: true } } }
        ) {
            group(field: fields___year) {
                fieldValue
                totalCount

                edges {
                    node {
                        id
                        excerpt(pruneLength: 280)
                        timeToRead
                        frontmatter {
                            title
                            slug
                            date(formatString: "MMMM Do")
                            year: date(formatString: "YYYY")
                        }
                        fields {
                            path
                        }
                    }
                }
            }
        }
    }
`;

const IndexPage = ({ data }) => {
    const sortedGroups = data.allMarkdownRemark.group.sort((g1, g2) => {
        const f1 = parseInt(g1.fieldValue);
        const f2 = parseInt(g2.fieldValue);

        return f2 - f1;
    });
    return (
        <Layout title={'Archive'}>
            {sortedGroups.map(({ fieldValue, totalCount, edges }, index) => {
                return (
                    <YearlySection key={fieldValue} className={index === sortedGroups.length - 1 ? 'last' : ''}>
                        <h2>
                            {fieldValue} <sup>({totalCount})</sup>
                        </h2>

                        {edges.map(({ node: { id, fields, frontmatter } }) => (
                            <PostLink to={fields.path} key={id}>
                                <PublishDate>{frontmatter.date}</PublishDate>
                                <PublishDate style={{ color: palette.gray }}>
                                    {', '} {frontmatter.year}
                                </PublishDate>
                                <h4>{frontmatter.title}</h4>
                            </PostLink>
                        ))}
                    </YearlySection>
                );
            })}
        </Layout>
    );
};

export default IndexPage;

const PostLink = styled(Link)`
    text-decoration: none;
    display: block;
    background: white;
    margin: 1.5rem 0;
    padding: 0.25rem 0;
    z-index: 1;
    position: relative;

    h4 {
        margin-bottom: 0;
    }
`;

const YearlySection = styled.div`
    padding-bottom: 2rem;
    position: relative;

    &.last:after {
        content: '';
        position: absolute;
        left: calc(1.5rem + 1px);
        width: 1rem;
        height: 1rem;
        bottom: 0;
        border: 1px solid ${palette.secondary};
        background: ${palette.lightGray};
        z-index: 0;
    }

    &:before {
        content: '';
        position: absolute;
        left: 2rem;
        top: -1.5rem;
        bottom: 0;
        width: 3px;
        background: ${palette.lightGray};
        z-index: 0;
    }

    h2 {
        display: inline-block;
        background: white;
        z-index: 1;
        position: relative;
        border-bottom: 3px solid ${palette.secondary};
        padding: 0.25rem 0.5rem;
    }
`;
